@import "@assets/reuse-mixin";

.content-center {
  justify-content: center;
  padding: 0;
}

.content-right {
  justify-content: flex-end;
}

.arrow-icon-down {
  position: relative;
  transform: rotate(90deg);
  width: 6px;
  height: 10px;
  transition: 0.2s;
  cursor: pointer;

  &.popup-show {
    transform: rotate(-90deg);
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: calc(50%);
    transform-origin: 6px center;
    width: 6px;
    height: 1px;
    background: $black;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }
}

.cp-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: $MENU_NAV;
  opacity: 0.4;

  &--black {
    background-color: $black;
  }
}

.cp-popup {
  position: absolute;
  z-index: $POPUP_ZINDEX;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-out;

  &.popup-show {
    opacity: 1;
    visibility: visible;
  }
}

.cp-header {
  transition: 0.5s;
  height: $CP_HEADER_HEIGHT;
  z-index: $HEADER_STICK_ZINDEX;

  p,
  h3 {
    margin-bottom: 0;
  }

  &.stick {
    .cp-header__wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: $HEADER_STICK_ZINDEX;
    }
  }

  &__wrapper {
    background: $white;
    border-bottom: 1px solid #ddd;
    height: $CP_HEADER_HEIGHT;
  }

  &__container {
    position: relative;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 13px 0;
    margin: 0 -6px;
  }

  &__col {
    width: calc((50% - 65px) / 3);
    display: flex;
    align-items: center;
    padding: 0 6px;
  }

  &__logo {
    width: 130px;
  }
}

.language-selection {
  color: $black;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  letter-spacing: 0.8px;

  > span {
    @include line-clamp(2);
  }

  span:first-child {
    margin-right: 8px;
    max-width: calc(100% - 10px);
    word-break: break-word;
  }

  > span {
    cursor: pointer;
  }
}

.btn-demo {
  width: 100%;
  background-color: $os-orange;
  color: $primary;
  padding: 10px 5px;
  border: none;
  outline: none;
  font-weight: $font-weight-bold;
  font-size: $font-size-smaller;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-small;
  }

  &:focus {
    outline: none;
  }

  &.--end {
    background-color: $success;
    color: $white;
  }
}

.store-name {
  padding-right: 0;
  letter-spacing: 0.8px;
  color: #7d7d7d;
  font-size: $font-size-small;
  line-height: 1;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
  }

  > span {
    @include line-clamp(2)
  }
}

.icon-group {
  display: flex;
  align-items: center;
}

.head-icon {
  margin-right: 16px;
  cursor: pointer;
  width: 25px;
  height: 25px;

  &.have-noti {
    > a,
    > span {
      display: inline-block;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $primary;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    > a,
    > span {
      opacity: 0.5;
    }
  }
}

.user-infor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .group-infor {
    margin-right: 10px;
    overflow: hidden;
    cursor: pointer;
  }

  &__name {
    color: $black;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    letter-spacing: 0.8px;

    @include line-clamp(1);
  }

  &__store-name {
    @include line-clamp(1);
  }
}

.header-icon-list {
  // width: calc((3 * (50% - 65px) / 5) - 62px);
  // width: 130px;
  width: auto;
  padding-left: 0;
}

.account-quick-access {
  width: calc(2 * (50% - 65px) / 5);
}

.infor-popup {
  top: $CP_HEADER_HEIGHT;
  right: 0;
  z-index: $POPUP_ZINDEX;
  width: 240px;
  padding: 10px 25px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  &.left-popup {
    right: auto;
    left: 0;
    width: auto;
    max-width: 240px;
  }

  .group-name {
    margin-bottom: 18px;
    letter-spacing: 0.7px;
    font-size: $font-size-small;
    color: $gray-darker;

    &__link {
      cursor: pointer;
    }

    > a:hover,
    > span:hover {
      color: $gray-darker;
      text-decoration: underline;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .list-store {
    margin: 10px 0 0 16px;
    max-height: 350px;
    overflow-y: auto;

    &__disable {
      > li {
        cursor: auto;
        pointer-events: none;
        opacity: 0.5;
      }
    }

    > li {
      padding-bottom: 8px;
      color: $gray;

      &:last-child {
        padding-bottom: 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .popup-sub-title {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
  }
}

.cp-search-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0;
  // width: 66px;
  width: calc((3 * (50% - 65px) / 5) - 130px);
  // width: calc((3 * (50% - 65px) / 5) - 47px);
}

.search-bar {
  top: 0;
  left: 0;
  width: 100%;
  height: $CP_HEADER_HEIGHT;

  &__container {
    max-width: 370px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  &__input {
    width: 320px;
    height: 42px;
    padding: 10px 16px;
    border: 1px solid $gray-dark;
    outline: none;
    background: none;
    color: $gray;
    letter-spacing: 0.9px;
    font-size: $font-size-large;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-left: 18px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.demo-session__custom__popup {
  .warning-popup__content__title {
    .cp-customer__name {
      font-weight: $font-weight-bold;
    }
  }
}
